<template>
  <div class="news-page">
    <div class="block-banner news-banner">
      <div class="bg--black">
        <div class="container">
          <h2 class="block-banner__title"  v-scroll-reveal="{
            distance: '80px',
            origin: 'left'
          }">NOTICIAS</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="news">
        <div class="news__container">
          <div class="news__wrapper row">
            <div class="news__item col-xs-12 col-md-4" v-for="item in news" :key="item.id">
              <div class="news__inner" v-scroll-reveal="{ delay: 400 }">
                <div class="news__img" :style="`background-image: url(/dailypacific/storage/app/public/${item.urlbanner})`">
                  <div class="news__info">
                    <div class="d-vertical">
                      <img class="news__info--img" src="@/assets/icons/icon-calendar.svg" />
                      <span class="news__info--date">{{item.updated_at | filterDateNew }}</span>
                    </div>
                  </div>
                </div>
                <div class="news__text">
                  <h3 class="news__text--title">{{item.titulo}}</h3>
                  <p class="news__text--description" v-html="item.descripcion"></p>
                  <div class="text-left">
                    <router-link :to="{ name: 'NewsDetailPage', params: { id: item.id } }">
                      <span class="icon-plus">+</span>
                      <span class="text">Leer más</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "NewsPage",
  props: {
    news: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      listNews: [],
    };
  },
  filters: {
    textService(val) {
      return `${val.slice(0, 80)}...`; 
    },
    filterDateNew(val) {
      function pad(s) { return (s < 10) ? '0' + s : s; }
      var d = new Date(val)
      return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
    }
  },
  created() {
    this.fetchNews();
  },
  methods: {
    fetchNews() {
      // eslint-disable-next-line no-console
      console.log('news');
    }
  }
}
</script>